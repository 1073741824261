.container {
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.header {
  font-size: 2em;
  margin-bottom: 10px;
}

.effectiveDate {
  font-style: italic;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

.subHeader {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.text {
  margin-bottom: 10px;
}

.contactDetails {
  font-style: normal;
  margin-top: 10px;
}