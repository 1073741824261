.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .updateDate {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .heading {
    font-size: 1.5rem;
    color: #444;
    margin-bottom: 10px;
  }
  
  .text {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .list {
    list-style-type: disc;
    margin-left: 20px;
    color: #555;
  }
  