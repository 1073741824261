.termsContainer {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .date {
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
    color: gray;
  }
  
  .sectionTitle {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  ul {
    margin-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  